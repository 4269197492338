<template>
  <div>
    <h1>{{ $t('views.saved_puzzles.h1_title') }}</h1>
    <v-alert v-if="!isAuthenticated" dense text color="info">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('views.saved_puzzles.p_not_authenticated') }}</p>
    </v-alert>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <Puzzles
      v-if="isAuthenticated"
      :puzzle-id="true"
      :stars="true"
      :progress="true"
      :checked="true"
      :updated-at="true"
      :action="true"
    ></Puzzles>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home'
import Puzzles from '@/components/Puzzles'
import { mapGetters } from 'vuex'

export default {
  name: 'SavedPuzzles',
  components: {
    Adslot,
    Puzzles,
    BtnHome,
  },
  computed: {
    ...mapGetters(['user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
  },
}
</script>

<style scoped></style>
